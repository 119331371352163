<template>
  <div class="home">
  </div>
</template>



<script>

export default {
  name: 'Home',

  components: {
  },

  computed:{
  },
  
  created () {
  // REDIRECT TO BUB on HOME 
    window.location.href = "https://www.brandunitberlin.de/digitale-gluecksraeder/";
  },

  mounted() {
  },

}

</script>

<style scoped>

</style>
