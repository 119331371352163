import axios from 'axios';

const state = {
	VERSION : "luckyX V.1.2",
	
  axiosHeader: { headers: {} },

  wheeldata: {},
  luckyApiUrl: 'luckyapi/',
  startLocalFromMobileUrl: 'mobileapi/startFromQR/',
  baseUrlFileUpload: 'fileUpload/',

  getNextPlayerUrl: 'mobileapi/countWarteliste/', // + myId 
  
  productiveUrl: 'http://weissstreicher.de/live/',

  projectFileNames: [],
  versionDataStructure: '0.2.0',
  
  giftStats: {},
  
	nextForcePrice: null, // {id: 0, turnsLeft: 4}
        
};	
// ============================ END STATE ===========================

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  apiUrl: (state, rootState) => { return rootState.baseApiUrl + state.luckyApiUrl },
  baseUrlFileUpload: (state, rootState) => { return rootState.baseApiUrl + state.baseUrlFileUpload },
  productiveUrl: state => { return state.productiveUrl},
  projectFileNames: state => { return state.projectFileNames},
  wheeldata: state => { return state.wheeldata },
  versionDataStructure: state => { return state.versionDataStructure },
  giftStats: state => {return state.giftStats},
  nextForcePrice: state => {return state.nextForcePrice},
};
// ===========================  END - GETTERS :::::::::::::::::::::::::

// ================== ACTIONS =========================
const actions = {
	
	checkForForcePrice ({commit, state}) {
      if (state.nextForcePrice != null) {
        commit("reduceForcedPriceCount");
      } 
    },
    
    clearForedPrices ({commit}) {
		commit("setForcedPrice", null);
	},
	
    setForcedPrice ({commit}, _data) {
		commit("setForcedPrice", _data);
	},
	
	/** set the forced Price in random spins */
	forcePriceNextX ({commit}, _id) {
		const spinOffset = 5;
		const spinRange = 20;
		let d = Math.floor(Math.random() * spinRange) + spinOffset;
		let p = {id: _id, turnsLeft: d};
		console.log(" X -> forcePriceNextX ->  ", p);
		commit("setForcedPrice", p);
	},
    
    async loadLuckyFromLocalStorage({dispatch}) {
      let tempvar =  window.localStorage.getItem('lucky')
      console.log("X -> loadLuckyFromLocalStorage : -> ", tempvar)
      if (tempvar) {
        dispatch('setWheelData', JSON.parse(tempvar))
      } else {
        //get the connections from your API
        //once you have the data, display it and also save it
        console.log("loadLuckyFromLocalStorage : -> init item: with ")
          // localStorage.setItem('userSet', state.userSet)
          window.localStorage.removeItem('lucky')
          window.localStorage.setItem('lucky', "{}")
      }
    },

    async loadLuckyFromServer({dispatch, state, rootState}, _name) {
      console.log(' X - loadLuckyFromServer : ' + _name )
      let url = rootState.baseApiUrl + state.luckyApiUrl + 'getLucky/' + _name
      let response = await axios.get(url, state.axiosHeader)
      console.log("loadLuckyFromServer -->",response.data)
      dispatch('setWheelData', response.data)
      console.log('X -> loadLuckyFromServer -> name: ' + _name + " -  res: ", response)
    },
    
    /** load the data local from disk ...  */
    async loadLuckyLocal({dispatch, state, rootState}, _name) {
      console.log(' X - loadLuckyLocal : ' + _name )
      let url = '/data/luckies/' + _name + ".json";
      let response = await axios.get(url, state.axiosHeader)
      console.log("loadLuckyLocal -->", response.data)
      dispatch('setWheelData', response.data)
    },

    async loadProjectNamesFromServer({dispatch, state, rootState}) {
      let url = rootState.baseApiUrl + state.luckyApiUrl + 'getconfigfilenames'
      let response = await axios.get(url, state.axiosHeader)
      dispatch('setProjectFileNames', response.data.fileNames)
    },

    saveLuckyToLocalStorage({},_luckyData) {
      console.log("!!!!!: ", JSON.stringify((_luckyData)))
      console.log('saveSettingsToLocalStorage ->  ', _luckyData)
       window.localStorage.setItem('lucky', JSON.stringify(_luckyData))
    },

    setProjectFileNames ({commit}, _data) {
      console.log('LuckyW_storeX -> setProjectFileNames _> action ')
       commit('setProjectFileNames', _data)
    },
   
    setWheelData ({commit}, _data) {
      console.log('LuckyW_storeX -> setWheelData _> action - make new random gift ')
      commit('setWheelData', _data)
    },

    setPlayerName ({rootState}, _data) {
      let url = rootState.baseApiUrl + "mobileapi/setplayername/" + _data
      let response = axios.get(url, this.axiosHeader)
      .then(response =>  console.log('sendData -> recieved: ' + response)) //response.data?
      .catch(err => { console.log(' X -> startLocalFromMobile -> Error ', err);
      });
    },
    
    setversionDataStructure ({commit}, _data) {
      commit("setversionDataStructure", _data)
    },

    
    // ---------------------- STORE STATS statistix ------------------------------------------
    /** TODO ...  */
    storeGiftStats ({commit}, _giftNR) {
      console.log("X -> storedGiftStats : -> ", _giftNR)
      commit("touchGiftStatNr", _giftNR); // JSON.parse(giftStats));
      // speichern der Häufigkeit 
    },
    
    loadGiftStats ({commit}, _wheeldata) {
		console.log("X -> loadGiftStats() ")
		commit("loadGiftStats", _wheeldata);
	},
    
    clearLocalStorage ({commit}) {
		console.log("X -> clearLocalStorage() ")
		commit("clearLocalStorage");
	},
	

    /** To Test ... 
    resetGiftStats ({commit, state}) {
     console.log("X -> resetGiftStats : ")
      let giftStats = {
        counter: 0,
     	resetTime: new Date().getTime(),
        sum: []
      };
      
      // set values to 0
      for (let i = 0; i < state.wheeldata.felder.length; i++) {
		  giftStats.sum[i] = 0;
	  }
      
      // clear localStorage and new init
      // store in localStorage. ... 
      window.localStorage.removeItem('giftStats');
      window.localStorage.setItem('giftStats',  JSON.stringify(giftStats));
      commit("setGiftStats", giftStats);
    }, */

};
// ================== END ACTION  =========================

// ================== MUTATIONS =========================
const mutations = {

    clearLocalStorage() {
      window.localStorage.clear();
    },
    
  setGiftStats(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setGiftStats', _data)
    state.giftStats = _data;
  },
  
  /** add 1 to stat counter for gift-id  and store it local */
  touchGiftStatNr(state, _id) {
	  state.giftStats.sum[_id] =  state.giftStats.sum[_id] +1;
	  state.giftStats.counter++;
	  window.localStorage.setItem(state.giftStats.name, JSON.stringify(state.giftStats));
  },
  
  loadGiftStats (state, _wheelData) {
	  const _name = _wheelData.projectName;
		console.log("X -> loadGiftStats : " + _name);
		let gStats =  window.localStorage.getItem(_name);
		if (gStats) {
			state.giftStats =  JSON.parse(gStats);
		} else {
			// const newStatObj = Array.from({length: _wheelData.felder.size}, () => 0); // mit Nullen gefülltes Array mit Länge der WheelFelder
			state.giftStats = {
				name: _name,
		        counter: 0,
		     	resetTime: new Date().getTime(),
		        sum: Array.from({length: _wheelData.felder.length}, () => 0)
	      	};
		      
       		window.localStorage.removeItem(_name);
        	window.localStorage.setItem(_name, JSON.stringify(state.giftStats));
      	}
	},
	
	
  setForcedPrice(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setForcedPrice', _data)
    state.nextForcePrice = _data;
  },
  
  reduceForcedPriceCount(state) {
    state.nextForcePrice.turnsLeft = state.nextForcePrice.turnsLeft -1;
  },
   
  /** set the wheel data
   * and make a new random gift ...
   */
  setWheelData(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setWheelData', _data)
    state.wheeldata = _data
  },


  setProjectFileNames(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setProjectFileNames', _data)
    state.projectFileNames = _data
  },

  setversionDataStructure(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setversionDataStructure', _data)
    state.setversionDataStructure = _data
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
 